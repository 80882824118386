<template>
  <div class="flashAnimate_wrap">
    <div id="scrollContainer">
      <div id="scrollContent">
        <!-- <div v-for="(item, index) in list" :key="index" class="list van-ellipsis">
          用户{{item.xuanyan_user_name}}参加了接力
        </div> -->
        <transition name="van-fade">
          <div v-show="state.visible" class="fs12 list van-ellipsis" v-if="!!currentData.xuanyan_user_name">
               用户{{currentData.xuanyan_user_name}}参加了接力
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "flashAnimate_wrap",
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      state: {
        visible: true,
        change: 0,
        timerVal: null,
      },

      currentData: {
        xuanyan_user_name: "",
      },
    };
  },

  destroyed() {
    if (!!this.state.timerVal) {
      clearInterval(this.state.timerVal);
    }
  },

  methods: {
    autoCarousel() {
      this.currentData = this.list[this.state.change];
      if (this.state.timerVal) clearInterval(this.state.timerVal);
      this.state.timerVal = setInterval(() => {
        if (!this.state.visible) {
          // 出现和消失是两个动画,出现时++,消失时不用++
          this.state.change++;
          if (this.state.change == this.list.length + 1) {
            this.state.change = 0;
          }
        }
        this.currentData = this.list[this.state.change];
        this.state.visible = !this.state.visible;
      }, 2000);
    },
  },
};
</script>

<style scoped lang='' src="./index.scss">
</style>