
/** 
 * 配置子组件需要直接import 不可使用懒加载
*/
import LongitudinalShuffling from './LongitudinalShuffling/index.vue'
import audioPlay from './audioPlay/index.vue'
import operateBtn from './operateBtn/index.vue'
import flashPlay from './flash/index.vue'
export default {
    audioPlay,
    LongitudinalShuffling,
    operateBtn,
    flashPlay
}