<template>
  <div id="LongitudinalShuffling">
    <div id="scrollContainer">
      <div id="scrollContent">
        <div v-for="(item, index) in list" :key="index" class="list van-ellipsis">
          用户{{item.xuanyan_user_name}}参加了接力
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LongitudinalShuffling",
  props: {
    scrollSpeed: {
      type: Number, //滚动的速度，越小滚动越快
      default: 45, //时间
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  mounted() {
    var scrollContainer = document.getElementById("scrollContainer");
    var scrollContent = document.getElementById("scrollContent");
    var stopscroll = false;
    var scrollContHeight = scrollContainer.clientHeight;
    var scrollContWidth = scrollContainer.clientWidth;
    var scrollSpeed = this.scrollSpeed;

    do {
      scrollContainer.appendChild(scrollContent.cloneNode(true));
    } while (scrollContainer.offsetHeight < scrollContHeight);

    scrollContainer.style.width = scrollContWidth + "px";
    scrollContainer.style.height = scrollContHeight + "px";
    scrollContainer.noWrap = true;

    function init() {
      scrollContainer.scrollTop = 0;
      setInterval(scrollUp, scrollSpeed);
    }
    init();

    var currTop = 0;
    function scrollUp() {
      if (stopscroll == true) return;
      currTop = scrollContainer.scrollTop;
      scrollContainer.scrollTop += 1;
      if (currTop == scrollContainer.scrollTop) {
        scrollContainer.scrollTop = 0;
        scrollContainer.scrollTop += 1;
      }
    }
  },
};
</script>

<style lang='scss' src="./index.scss">
</style>