<template>
  <div class="audio_bg_wrap">
    <div class="audio_icon" :class='{"audio_animation": finalOptions.isPlay}' data-mp3="0" style="z-index: 9;">

      {{finalOptions.title}}
      <audio class="audio_self" ref="audioPlayer" style="margin-top: 8px" :src="finalOptions.url" controls="true"
        controlslist="nodownload" id="voice" @ended="overAudio" :loop="finalOptions.loop">
      </audio>
      <img :src="finalOptions.cover" alt="" class="audio_pic" v-if="!!finalOptions.cover">
    </div>
  </div>
</template>
 
<script>
import { Toast } from "vant";

export default {
  name: "APlayer",
  props: {
    currentBGM: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },

  computed: {
    finalOptions: {
      get() {
        return this.currentBGM
      },
    },
  },

  methods: {
    resetPlay() {
      this.$refs.audioPlayer.load();
    },

    // 默认播放
    defaultPlay() {
      // Toast("背景音乐默认播放");
      this.$refs.audioPlayer.play();
    },

    // 开始播放
    resumePlay() {
      // Toast("背景音乐开始播放");
      this.$refs.audioPlayer.play();
    },

    // 暂停播放
    pausePlay() {
      // Toast("背景音乐暂停");
      this.$refs.audioPlayer.pause();
    },

    //播放完毕执行
    overAudio() {
      // console.log("播放声音完毕");
      this.$emit("overPlay");
    },
  },
};
</script>
<style lang="scss" src='./index.scss'>
</style>