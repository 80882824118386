import http from "@/utils/request.js";
const baseURL = process.env.NODE_ENV === "test" ? "https://kan0519.cztv.tv/api/v1" : "https://kcz.cztv.tv/api/v1";
const czhURL = process.env.NODE_ENV === "test" ? "https://mp.netfishing.cn/api" : "https://apiczh.cztv.tv/api";

export function getXuanyaniIndex(articleId) {
  return http({
    url: `${baseURL}/xuanyan/index/data`,
    method: "get",
  });
}

export function getAudiosDetail(id) {
  return http({
    url: `${baseURL}/xuanyan/audios/${id}`,
    method: "get",
  });
}