<template>
  <div class="operate_btn_wrap">
    <div style="position: relative;">
      <wx-open-launch-app v-if="isWeixin && is_version" id="launch-btn" :appid="appId" :extinfo="extinfo" class="open-launch-app" @error="openError">
        <script type="text/wxtag-template">
          <style>.btn{
                  width: 100%;
                  height: 45px;
                  background: transparent;
                }</style>
            <div class="btn"></div>
        </script>
      </wx-open-launch-app>
      <div class="default_button_claret_red flex-cc" @click="linkApp">
        我来接力读宣言
      </div>
    </div>
    <div style="position: relative;">
      <wx-open-launch-app v-if="isWeixin && is_version" id="launch-btn" :appid="appId" :extinfo="extinfo" class="open-launch-app" @error="openError">
        <script type="text/wxtag-template">
          <style>.btn{
                  width: 100%;
                  height: 45px;
                  background: transparent;
                }</style>
            <div class="btn"></div>
        </script>
      </wx-open-launch-app>
      <div class="claret_red_color flex-cc" @click="linkApp">
        <span class="disk"></span>
        语音留声机
      </div>
    </div>

    <!-- <div class="linkApp" @click="linkApp">我来接力读宣言</div> -->
  </div>
</template>

<script>
  import linkApp from "@/utils/linkApp";
  import { isWeixin } from "@/utils/common";
  import { wxShare, init, is_version } from "@/utils/wxShare";
  export default {
    name: "openApp",
    props: {
      path: {
        type: String,
        default: "",
      },
      id: {
        type: [String, Number],
        default: "",
      },
      channelId: {
        type: [String, Number],
        default: "",
      },
      date: {
        type: [String, Number],
        default: "",
      },
      programId: {
        type: [String, Number],
        default: "",
      },
      url: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        appId: "wx720bb4d01c924127",
        isWeixin: isWeixin(),
        is_version: is_version(),
      };
    },
    computed: {
      extinfo() {
        return JSON.stringify({
          path: "/" + this.path,
          url: this.url,
          id: this.id,
        });
      },
    },
    created() {},
    methods: {
      linkApp() {
        const data = {
          path: this.path,
          url: this.url,
          param: this.id,
          channelId: this.channelId,
          date: this.date,
          programId: this.programId,
        };
        linkApp(data);
      },
      openError() {
        this.$router.push({ name: "index" });
      },
    },
    mounted() {},
  };
</script>
<style lang="scss" src="./index.scss"></style>
