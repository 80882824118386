import axios from "axios";
import { setStore, getStore, clearStore } from "@/utils/storage";
// 创建 axios 实例
let http = axios.create({
  // headers: {'Content-Type': 'application/json'},
  timeout: 60000,
  headers: {
    // "Content-Type": "application/json",
    // device: store.getters["fingerID"],
    // "device-token": "",
    // "device-model": isIos ? "iPhone" : "Android",
    // "device-os": "",
    // "device-product": "",
    // "device-type": isIos ? 2 : 1,
    // "device-size": "",
    // "os-image-height": "",
    // "os-image-width": "",
    // "app-version": "",
    // "interface-code": "",
    // "os-latitude": "",
    // "os-longitude": "",
    // nonce: randomString(16),
    // timestamp: parseInt(new Date().getTime() * 1000),
    // "secret-key": "",
    // signature: "",
    // "app-system": "h5",
  },
});

// 添加请求拦截器
http.interceptors.request.use(
  async config => {
    // 请求发送前进行处理
    if (getStore("accessToken")) {
      config.headers["Authorization"] = getStore("accessToken"); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config;
  },
  error => {
    // 请求错误处理
    return Promise.reject(error);
  }
);

// 添加响应拦截器
http.interceptors.response.use(
  response => {
    let { data } = response;
    return data;
  },
  error => {
    let info = {};
    let { status, statusText, data } = error.response;
    if (!error.response) {
      info = {
        code: 5000,
        msg: "Network Error",
      };
    } else {
      // 此处整理错误信息格式
      info = {
        code: status,
        data: data,
        msg: statusText,
      };
    }
    return Promise.reject(info);
  }
);

export default http;
